import Layout from '@/layout'

export const punchCardRoutes = [
  {
    path: '/punch-card',
    component: Layout,
    name: 'punchCard',
    alwaysShow: true,
    isGroup: true,
    meta: {
      title: '次卡模組',
    },
    children: [
      {
        path: 'setting',
        name: 'PunchCardSetting',
        component: () => import('@/views/PunchCard/PunchCardSetting'),
        meta: {
          title: '次卡設定',
          // action: 'admin.punchCard.page', // TODO: 記得加上權限
        },
      },
      {
        path: '/create-punch-card/:punchCardId?',
        name: 'CreatePunchCard',
        hidden: true,
        component: () => import('@/views/PunchCard/CreatePunchCard/CreatePunchCard'),
        meta: {
          title: '新增堂票',
          // action: 'admin.punchCard.page', // TODO: 記得加上權限
        },
      },
      {
        path: 'recode-list',
        name: 'PunchCardRecordList',
        component: () => import('@/views/PunchCard/PunchCardRecord'),
        meta: {
          title: '次卡紀錄',
          // action: 'admin.punchCardRecord.page', // TODO: 記得加上權限
        },
      },
    ],
  },
]
